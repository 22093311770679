import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { computed } from 'vue';
import SwiperNavigationPoints from 'web/src/components/Swiper/Navigation/SwiperNavigationPoints/SwiperNavigationPoints.vue';
import VSwiperSlide from 'web/src/components/Swiper/VSwiperSlide/VSwiperSlide.vue';
import VSwiper from 'web/src/components/Swiper/VSwiper/VSwiper';
import { VLoaderDelayed } from 'web/src/components/Loader';
import AdventCalendarItem from '../AdventCalendarItem/AdventCalendarItem.vue';
import AdventButton from '../AdventButton/AdventButton.vue';
import { useAdventCalendar } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'AdventCalendar',
    props: {
        items: {},
        imageDirectory: {},
        isPackCalculating: {
            type: Boolean
        },
        isPacksLoaded: {
            type: Boolean
        }
    },
    emits: [
        "click",
        "watch-bonuses"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        computed(()=>{
            const groups = [];
            if (props.items) for(let i = 0; i < props.items.length; i += 6)groups.push(props.items.slice(i, i + 6));
            return groups;
        });
        function emitClick(day) {
            emit('click', day);
        }
        function emitWatchBonuses() {
            emit('watch-bonuses');
        }
        const { stubsItems, hasStubs } = useAdventCalendar(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['advent-calendar'])
            }, [
                _ctx.isPacksLoaded ? (_openBlock(), _createElementBlock(_Fragment, {
                    key: 1
                }, [
                    (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['advent-calendar__grid'])
                    }, [
                        _unref(hasStubs) ? (_openBlock(true), _createElementBlock(_Fragment, {
                            key: 0
                        }, _renderList(_unref(stubsItems), (item, index)=>(_openBlock(), _createBlock(AdventCalendarItem, {
                                key: index,
                                day: item,
                                "closed-at": 0,
                                "is-stub": ""
                            }, null, 8, [
                                "day"
                            ]))), 128)) : _createCommentVNode("", true),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index)=>(_openBlock(), _createBlock(AdventCalendarItem, {
                                key: index,
                                "is-calculated": item.calculated,
                                "is-collected": item.collected,
                                "closed-at": item.closedAt,
                                day: item.day,
                                image: item.visualDetails.image || void 0,
                                "started-at": item.startedAt,
                                state: item.state,
                                text: item.visualDetails.text,
                                title: item.visualDetails.title || void 0,
                                class: _normalizeClass(_ctx.$style['advent-calendar__grid-item']),
                                "is-pack-calculating": _ctx.isPackCalculating,
                                "image-directory": _ctx.imageDirectory,
                                onClick: emitClick
                            }, null, 8, [
                                "is-calculated",
                                "is-collected",
                                "closed-at",
                                "day",
                                "image",
                                "started-at",
                                "state",
                                "text",
                                "title",
                                "class",
                                "is-pack-calculating",
                                "image-directory"
                            ]))), 128))
                    ], 2)),
                    _createVNode(AdventButton, {
                        class: _normalizeClass(_ctx.$style['advent-calendar__button']),
                        kind: "primary",
                        size: "large",
                        label: _ctx.$t('WEB2_LANDING_ADVENT_KEEP_TRACK_YOUR_BONUS'),
                        onClick: emitWatchBonuses
                    }, null, 8, [
                        "class",
                        "label"
                    ])
                ], 64)) : (_openBlock(), _createBlock(_unref(VLoaderDelayed), {
                    key: 0
                }))
            ], 2)), [
                [
                    _directive_auto_id,
                    'AdventCalendar'
                ]
            ]);
        };
    }
});
