import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "landing-section-faq__body"
};
const _hoisted_2 = {
    class: "landing-section-faq__header"
};
const _hoisted_3 = {
    key: 0,
    class: "landing-section-faq__title"
};
const _hoisted_4 = {
    class: "landing-section-faq__list-body"
};
const _hoisted_5 = {
    class: "landing-section-faq__list"
};
import LandingSectionFaqList from 'web/src/modules/landings/system/sections/FaqSection/LandingSectionFaqList.vue';
import VAnimationStarter from 'web/src/components/AnimationStarter/VAnimationStarter/VAnimationStarter.vue';
import { useLandingSectionFaq } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LandingSectionFaq',
    props: {
        title: {},
        faqList: {}
    },
    setup (__props) {
        const props = __props;
        const { faqListFirst, faqListSecond, openedItemId, toggleItem } = useLandingSectionFaq(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(VAnimationStarter, {
                "root-margin": "0px 0px -50px 0px",
                class: "landing-section-faq"
            }, {
                default: _withCtx(()=>[
                        _createElementVNode("div", _hoisted_1, [
                            _createElementVNode("div", _hoisted_2, [
                                _ctx.title ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.title), 1)) : _createCommentVNode("", true)
                            ]),
                            _createElementVNode("div", _hoisted_4, [
                                _createElementVNode("div", _hoisted_5, [
                                    _createVNode(LandingSectionFaqList, {
                                        list: _unref(faqListFirst),
                                        "opened-item-id": _unref(openedItemId),
                                        onClick: _unref(toggleItem)
                                    }, null, 8, [
                                        "list",
                                        "opened-item-id",
                                        "onClick"
                                    ]),
                                    _createVNode(LandingSectionFaqList, {
                                        list: _unref(faqListSecond),
                                        "opened-item-id": _unref(openedItemId),
                                        onClick: _unref(toggleItem)
                                    }, null, 8, [
                                        "list",
                                        "opened-item-id",
                                        "onClick"
                                    ])
                                ])
                            ])
                        ])
                    ]),
                _: 1
            })), [
                [
                    _directive_auto_id,
                    'LandingSectionFaq'
                ]
            ]);
        };
    }
});
