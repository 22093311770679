import { defineComponent as _defineComponent } from 'vue';
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "src"
];
import AdventButton from '../AdventButton/AdventButton.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'AdventCalendarStub',
    props: {
        imageDirectory: {},
        isStarted: {
            type: Boolean
        },
        isLoggedIn: {
            type: Boolean
        },
        isFestivalUnavailable: {
            type: Boolean
        }
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        function emitClick() {
            emit('click');
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['advent-calendar-stub'])
            }, [
                _createElementVNode("img", {
                    src: require(`web/src/modules/landings/submodules/advent/images/${_ctx.imageDirectory}/desktop-stub.png`),
                    alt: "",
                    class: _normalizeClass(_ctx.$style['advent-calendar-stub__img'])
                }, null, 10, _hoisted_1),
                _ctx.isFestivalUnavailable ? _createCommentVNode("", true) : (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['advent-calendar-stub__text'])
                }, _toDisplayString(_ctx.$t('WEB2_LANDING_ADVENT_STUB_BEFORE_START_TITLE')), 3)),
                _ctx.isStarted || !_ctx.isLoggedIn ? (_openBlock(), _createBlock(AdventButton, {
                    key: 1,
                    kind: "primary",
                    size: 'large',
                    label: _ctx.isFestivalUnavailable ? _ctx.$t('WEB2_LANDING_ADVENT_UNAVAILABLE') : _ctx.$t('WEB2_LANDING_ADVENT_LOGIN'),
                    class: _normalizeClass(_ctx.$style['advent-calendar-stub__button']),
                    disabled: _ctx.isFestivalUnavailable,
                    onClick: emitClick
                }, null, 8, [
                    "size",
                    "label",
                    "class",
                    "disabled"
                ])) : _createCommentVNode("", true),
                _ctx.isFestivalUnavailable ? (_openBlock(), _createElementBlock("div", {
                    key: 2,
                    class: _normalizeClass(_ctx.$style['advent-calendar-stub__hint'])
                }, _toDisplayString(_ctx.$t('WEB2_LANDING_ADVENT_STUB_UNAVAILABLE_TEXT')), 3)) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'AdventCalendarStub'
                ]
            ]);
        };
    }
});
