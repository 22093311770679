import { useRouter } from 'vue-router';
import RouteName from '@leon-hub/routing-config-names';
import SportlineRouteName from '@leon-hub/routing-config-names/src/SportlineRouteName';
import { useNativeAppInterface } from 'web/src/modules/native-apps/composables/useNativeAppInterface';
import useHomePageType from 'web/src/modules/core/store/composables/useHomePageType';
export function useLandingButtonsClickHandlers() {
    const router = useRouter();
    const nativeAppInterface = useNativeAppInterface();
    const { routeNameToSportLine } = useHomePageType();
    function openPromotion(param) {
        let { categoryId, actionUrl } = param;
        if (!!categoryId && !!actionUrl) {
            const promoRoute = {
                name: RouteName.PROMOTION_DETAILS,
                params: {
                    categoryId,
                    actionUrl
                }
            };
            if (nativeAppInterface.isWebView.value) nativeAppInterface.openPromotion(router.resolve(promoRoute).path);
            else router.push(promoRoute);
        }
    }
    function openMyBonuses() {
        if (nativeAppInterface.isWebView.value) nativeAppInterface.openBonuses();
        else router.push({
            name: RouteName.BONUSES
        });
    }
    function openLogin() {
        if (nativeAppInterface.isWebView.value) nativeAppInterface.openLogin();
        else router.push({
            name: RouteName.LOGIN
        });
    }
    function openAllPromotions() {
        if (nativeAppInterface.isWebView.value) nativeAppInterface.openAllPromotions();
        else router.push({
            name: RouteName.PROMOTIONS
        });
    }
    function openTop() {
        "1";
        if (nativeAppInterface.isWebView.value) nativeAppInterface.openTop();
        else router.push({
            name: routeNameToSportLine.value
        });
    }
    function openLive() {
        "1";
        if (nativeAppInterface.isWebView.value) nativeAppInterface.openTop();
        else router.push({
            name: SportlineRouteName.SPORTLINE_LIVE_EVENTS
        });
    }
    return {
        openPromotion,
        openLogin,
        openMyBonuses,
        openAllPromotions,
        openTop,
        openLive
    };
}
