import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "href"
];
const _hoisted_2 = {
    class: "landing-android-button__image"
};
const _hoisted_3 = [
    "src"
];
const _hoisted_4 = {
    class: "landing-android-button__label"
};
import VAnimationStarter from 'web/src/components/AnimationStarter/VAnimationStarter/VAnimationStarter.vue';
import { useLandingAndroidButton } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LandingAndroidButton',
    setup (__props) {
        const { appDownloadLink } = useLandingAndroidButton();
        const iconName = 'download';
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(VAnimationStarter, {
                "root-margin": "0px 0px -50px 0px",
                threshold: 0.8
            }, {
                default: _withCtx(()=>[
                        _createElementVNode("a", {
                            class: "landing-android-button",
                            href: _unref(appDownloadLink)
                        }, [
                            _createElementVNode("span", _hoisted_2, [
                                _createElementVNode("img", {
                                    src: require(`../../images/components/button/${_unref(iconName)}.svg`),
                                    alt: "download",
                                    style: {
                                        width: "100%"
                                    }
                                }, null, 8, _hoisted_3)
                            ]),
                            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('WEB2_ANDROID_LANDING_DOWNLOAD_APP')), 1)
                        ], 8, _hoisted_1)
                    ]),
                _: 1
            })), [
                [
                    _directive_auto_id,
                    'LandingAndroidButton'
                ]
            ]);
        };
    }
});
