import { onMounted, computed, toRef } from 'vue';
import { useRouter } from 'vue-router';
import RouteName from '@leon-hub/routing-config';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import { useEventsBus } from '@leon-hub/event-bus';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useTheme } from 'web/src/modules/theme/composables';
import { useIntercomEvents } from 'web/src/modules/intercom/composables';
export default function useLandingVipRoutePage() {
    const router = useRouter();
    const analytics = useAnalytics();
    const { isLoggedIn } = useIsLoggedIn();
    const { isDark } = useTheme();
    useEventsBus();
    const { onHide } = useIntercomEvents();
    const faqItems = toRef(useSiteConfigStore(), 'landingVipFaqItems');
    const folder = toRef(()=>isDark.value ? 'dark' : 'light');
    const faqListFormatted = computed(()=>faqItems.value.map((item, index)=>({
                ...item,
                id: index + 1
            })));
    function onMainButtonClick() {
        if (isLoggedIn.value) analytics.push(AnalyticsEvent.Z_AUTH_BECOME_VIP_BUTTON, {
            vip_program: {
                become_a_vip_button: 'auth'
            }
        });
        else analytics.push(AnalyticsEvent.Z_NON_AUTH_BECOME_VIP_BUTTON, {
            vip_program: {
                become_a_vip_button: 'non_auth'
            }
        });
        router.push({
            name: RouteName.DEPOSITS
        });
    }
    function onContactUsButtonClick() {
        if (isLoggedIn.value) analytics.push(AnalyticsEvent.Z_AUTH_CONTACT_US_BUTTON, {
            vip_program: {
                contact_us_button: 'auth'
            }
        });
        else analytics.push(AnalyticsEvent.Z_NON_AUTH_CONTACT_US_BUTTON, {
            vip_program: {
                contact_us_button: 'non_auth'
            }
        });
    }
    onMounted(()=>{
        if (isLoggedIn.value) analytics.push(AnalyticsEvent.Z_AUTH_VISIT_VIP, {
            vip_program: {
                visit: 'auth'
            }
        });
        else analytics.push(AnalyticsEvent.Z_NON_AUTH_VISIT_VIP, {
            vip_program: {
                visit: 'non_auth'
            }
        });
    });
    return {
        folder,
        faqListFormatted,
        onMainButtonClick,
        onContactUsButtonClick
    };
}
