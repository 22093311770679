import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, unref as _unref, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "landing-android-third"
};
const _hoisted_2 = {
    class: "landing-android-third__column"
};
const _hoisted_3 = {
    class: "landing-android-third__column"
};
import { toRef } from 'vue';
import VDynamicContent from 'web/src/components/DynamicContent/VDynamicContent/VDynamicContent.vue';
import LandingAndroidChevron from 'web/src/modules/landings/submodules/android/pages/components/LandingAndroidChevron/LandingAndroidChevron.vue';
import LandingAndroidButton from 'web/src/modules/landings/submodules/android/pages/components/LandingAndroidButton/LandingAndroidButton.vue';
import VImage from 'web/src/components/Image/VImage/VImage.vue';
import VAnimationStarter from 'web/src/components/AnimationStarter/VAnimationStarter/VAnimationStarter.vue';
import LandingAndroidTitle from 'web/src/modules/landings/submodules/android/pages/components/LandingAndroidTitle/LandingAndroidTitle.vue';
import { useLandingImages } from 'web/src/modules/landings/submodules/android/pages/useLandingImages';
import VEmpty from 'web/src/components/Empty/components/VEmpty';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LandingAndroidThird',
    setup (__props) {
        const LandingAndroidAppStores = // eslint-disable-next-line max-len
        VEmpty;
        const thirdImage = toRef(useLandingImages(), 'thirdImage');
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createVNode(VAnimationStarter, {
                        "root-margin": "0px 0px -50px 0px"
                    }, {
                        default: _withCtx(()=>[
                                _createVNode(LandingAndroidChevron, {
                                    label: _ctx.$t('WEB2_ANDROID_LANDING_CHEVRON_3'),
                                    class: "landing-android-third__chevron"
                                }, null, 8, [
                                    "label"
                                ]),
                                _createVNode(LandingAndroidTitle, {
                                    class: "landing-android-third__title",
                                    label: _ctx.$t('WEB2_ANDROID_LANDING_TITLE_3')
                                }, null, 8, [
                                    "label"
                                ]),
                                _createVNode(VDynamicContent, {
                                    class: "landing-android-third__label android-label",
                                    content: _ctx.$t('WEB2_ANDROID_LANDING_LABEL_3')
                                }, null, 8, [
                                    "content"
                                ]),
                                _createVNode(LandingAndroidButton, {
                                    class: "landing-android-third__button"
                                }),
                                _createVNode(_unref(LandingAndroidAppStores), {
                                    class: "landing-android-third__app"
                                })
                            ]),
                        _: 1
                    })
                ]),
                _createElementVNode("div", _hoisted_3, [
                    _createVNode(VAnimationStarter, {
                        "root-margin": "0px 0px -50px 0px"
                    }, {
                        default: _withCtx(()=>[
                                (_openBlock(), _createBlock(VImage, {
                                    key: 1,
                                    class: "landing-android-third__img",
                                    src: thirdImage.value
                                }, null, 8, [
                                    "src"
                                ]))
                            ]),
                        _: 1
                    })
                ])
            ])), [
                [
                    _directive_auto_id,
                    'LandingAndroidThird'
                ]
            ]);
        };
    }
});
