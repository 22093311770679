import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "onClick"
];
const _hoisted_2 = {
    class: "landing-section-faq-list__item-header"
};
const _hoisted_3 = {
    class: "landing-section-faq-list__item-title"
};
const _hoisted_4 = {
    class: "landing-section-faq-list__item-button"
};
const _hoisted_5 = {
    key: 0,
    class: "landing-section-faq-list__item-description"
};
import { IconName } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import VAnimationStarter from 'web/src/components/AnimationStarter/VAnimationStarter/VAnimationStarter.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LandingSectionFaqList',
    props: {
        list: {},
        openedItemId: {}
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        function onClickItem(id, title) {
            emit('click', id, title);
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(VAnimationStarter, {
                "root-margin": "0px 0px -50px 0px",
                class: "landing-section-faq-list"
            }, {
                default: _withCtx(()=>[
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item)=>(_openBlock(), _createElementBlock("div", {
                                key: item.id,
                                class: _normalizeClass({
                                    'landing-section-faq-list__item': true,
                                    'landing-section-faq-list__item--is-open': item.id === _ctx.openedItemId
                                }),
                                onClick: ($event)=>onClickItem(item.id, item.title)
                            }, [
                                _createElementVNode("div", _hoisted_2, [
                                    _createElementVNode("span", _hoisted_3, _toDisplayString(item.title), 1),
                                    _createElementVNode("span", _hoisted_4, [
                                        _createVNode(_unref(VIcon), {
                                            name: _unref(IconName).PLUS_SMALL,
                                            class: _normalizeClass({
                                                'landing-section-faq-list__item-icon': true,
                                                'landing-section-faq-list__item-icon--is-open': item.id === _ctx.openedItemId
                                            })
                                        }, null, 8, [
                                            "name",
                                            "class"
                                        ])
                                    ])
                                ]),
                                item.id === _ctx.openedItemId ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(item.description), 1)) : _createCommentVNode("", true)
                            ], 10, _hoisted_1))), 128))
                    ]),
                _: 1
            })), [
                [
                    _directive_auto_id,
                    'LandingSectionFaqList'
                ]
            ]);
        };
    }
});
