import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { Tag } from '@leon-hub/tags';
import VDynamicContent from 'web/src/components/DynamicContent/VDynamicContent/VDynamicContent.vue';
import AdventHeroTimer from '../AdventHeroTimer/AdventHeroTimer.vue';
import AdventSectionTitle from '../AdventSectionTitle/AdventSectionTitle.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'AdventHero',
    props: {
        timestamp: {},
        isBeforeStart: {
            type: Boolean
        },
        isFestivalEnded: {
            type: Boolean
        }
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['advent-hero'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['advent-hero__wrapper'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['advent-hero__heading'])
                    }, [
                        _createVNode(AdventSectionTitle, {
                            class: _normalizeClass(_ctx.$style['advent-hero__title']),
                            tag: _unref(Tag).H1
                        }, {
                            text: _withCtx(()=>[
                                    _createVNode(VDynamicContent, {
                                        content: _ctx.$t('WEB2_LANDING_ADVENT_MAIN_SUBTITLE')
                                    }, null, 8, [
                                        "content"
                                    ])
                                ]),
                            default: _withCtx(()=>[
                                    _createVNode(VDynamicContent, {
                                        content: _ctx.$t('WEB2_LANDING_ADVENT_MAIN_TITLE')
                                    }, null, 8, [
                                        "content"
                                    ])
                                ]),
                            _: 1
                        }, 8, [
                            "class",
                            "tag"
                        ])
                    ], 2),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['advent-hero__timer'])
                    }, [
                        _ctx.timestamp > 0 ? (_openBlock(), _createBlock(AdventHeroTimer, {
                            key: 0,
                            timestamp: _ctx.timestamp,
                            "is-before-start": _ctx.isBeforeStart,
                            "is-festival-ended": _ctx.isFestivalEnded
                        }, null, 8, [
                            "timestamp",
                            "is-before-start",
                            "is-festival-ended"
                        ])) : _createCommentVNode("", true)
                    ], 2)
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'AdventHero'
                ]
            ]);
        };
    }
});
