import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "landing-android-first-content"
};
const _hoisted_2 = {
    class: "landing-android-first-content__column landing-android-first-content__column--left"
};
const _hoisted_3 = {
    class: "landing-android-first-content__column"
};
import LandingAndroidChevron from 'web/src/modules/landings/submodules/android/pages/components/LandingAndroidChevron/LandingAndroidChevron.vue';
import LandingAndroidButton from 'web/src/modules/landings/submodules/android/pages/components/LandingAndroidButton/LandingAndroidButton.vue';
import VDynamicContent from 'web/src/components/DynamicContent/VDynamicContent/VDynamicContent.vue';
import LandingAndroidFirstImage from 'web/src/modules/landings/submodules/android/pages/blocks/LandingAndroidFirst/components/LandingAndroidFirstImage.vue';
import VEmpty from 'web/src/components/Empty/components/VEmpty';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LandingAndroidFirstContent',
    setup (__props) {
        const LandingAndroidAppStores = // eslint-disable-next-line max-len
        VEmpty;
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createVNode(LandingAndroidChevron, {
                        star: "",
                        label: _ctx.$t('WEB2_ANDROID_LANDING_CHEVRON_1')
                    }, null, 8, [
                        "label"
                    ]),
                    _createVNode(VDynamicContent, {
                        class: "landing-android-first-content__title landing-android-title",
                        content: _ctx.$t('WEB2_ANDROID_LANDING_TITLE_1')
                    }, null, 8, [
                        "content"
                    ]),
                    _createVNode(VDynamicContent, {
                        class: "landing-android-first-content__label",
                        content: _ctx.$t('WEB2_ANDROID_LANDING_LABEL_1')
                    }, null, 8, [
                        "content"
                    ]),
                    _createVNode(LandingAndroidButton),
                    _createVNode(_unref(LandingAndroidAppStores))
                ]),
                _createElementVNode("div", _hoisted_3, [
                    _createCommentVNode("", true)
                ])
            ])), [
                [
                    _directive_auto_id,
                    'LandingAndroidFirstContent'
                ]
            ]);
        };
    }
});
