import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, vShow as _vShow, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { computed } from 'vue';
import { ButtonHeight, ButtonKind } from '@leon-hub/module-buttons';
import { IconName, IconSize } from '@leon-hub/icons';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { useSwiperNavigation } from 'web/src/components/Swiper/Navigation/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'AdventCalendarTournamentsSliderNavigation',
    setup (__props) {
        const { swiperState, slideToPreviousVisibleSlide, slideToNextVisibleSlide } = useSwiperNavigation();
        const isShowButtons = computed(()=>!!swiperState?.isPreviousButtonAllowed.value || !!swiperState?.isNextButtonAllowed.value);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['advent-calendar-tournaments-slider-navigation'])
            }, [
                _createVNode(VButton, {
                    kind: _unref(ButtonKind).BASE,
                    height: _unref(ButtonHeight).X_LARGE,
                    "icon-name": _unref(IconName).EXPAND_LEFT,
                    "icon-size": _unref(IconSize).SIZE_24,
                    disabled: !_unref(swiperState)?.isPreviousButtonAllowed.value,
                    rounded: "",
                    class: _normalizeClass(_ctx.$style['advent-calendar-tournaments-slider-navigation__button']),
                    onClick: _unref(slideToPreviousVisibleSlide)
                }, null, 8, [
                    "kind",
                    "height",
                    "icon-name",
                    "icon-size",
                    "disabled",
                    "class",
                    "onClick"
                ]),
                _createVNode(VButton, {
                    kind: _unref(ButtonKind).BASE,
                    height: _unref(ButtonHeight).X_LARGE,
                    "icon-name": _unref(IconName).EXPAND_RIGHT,
                    "icon-size": _unref(IconSize).SIZE_24,
                    disabled: !_unref(swiperState)?.isNextButtonAllowed.value,
                    rounded: "",
                    class: _normalizeClass(_ctx.$style['advent-calendar-tournaments-slider-navigation__button']),
                    onClick: _unref(slideToNextVisibleSlide)
                }, null, 8, [
                    "kind",
                    "height",
                    "icon-name",
                    "icon-size",
                    "disabled",
                    "class",
                    "onClick"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'AdventCalendarTournamentsSliderNavigation'
                ],
                [
                    _vShow,
                    isShowButtons.value
                ]
            ]);
        };
    }
});
