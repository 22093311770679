import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { Tag } from '@leon-hub/tags';
import SwiperNavigationPoints from 'web/src/components/Swiper/Navigation/SwiperNavigationPoints/SwiperNavigationPoints.vue';
import VSwiperSlide from 'web/src/components/Swiper/VSwiperSlide/VSwiperSlide.vue';
import VDynamicContent from 'web/src/components/DynamicContent/VDynamicContent/VDynamicContent.vue';
import VSwiper from 'web/src/components/Swiper/VSwiper/VSwiper';
import { useAdventCalendarTournaments } from './composables';
import AdventCalendarTournamentItem from '../AdventCalendarTournamentItem/AdventCalendarTournamentItem.vue';
import AdventCalendarTournamentsSliderNavigation from '../AdventCalendarTournamentsSliderNavigation/AdventCalendarTournamentsSliderNavigation.vue';
import AdventSectionTitle from '../AdventSectionTitle/AdventSectionTitle.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'AdventCalendarTournaments',
    props: {
        isFestivalUnavailable: {
            type: Boolean
        }
    },
    emits: [
        "click-on-details"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        const { promotions } = useAdventCalendarTournaments();
        function clickOnDetails(promotion) {
            emit('click-on-details', {
                categoryId: promotion.categoryId,
                actionUrl: promotion.actionUrl
            });
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _unref(promotions).length ? _withDirectives((_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(_ctx.$style['advent-calendar-tournaments'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['advent-calendar-tournaments__swiper'])
                }, [
                    _createVNode(_unref(VSwiper), {
                        ref: "swiper",
                        "use-modern-snap-api": false,
                        "is-scroll-snap-enabled": "",
                        "event-container-class": _ctx.$style['advent-calendar-tournaments__event-container']
                    }, {
                        "pagination-header": _withCtx(()=>[
                                _createElementVNode("div", {
                                    class: _normalizeClass(_ctx.$style['advent-calendar-tournaments__header'])
                                }, [
                                    _createVNode(AdventSectionTitle, {
                                        tag: _unref(Tag).H2
                                    }, {
                                        text: _withCtx(()=>[
                                                _createVNode(VDynamicContent, {
                                                    content: _ctx.$t('WEB2_LANDING_ADVENT_TOURNAMENTS_DESCRIPTION')
                                                }, null, 8, [
                                                    "content"
                                                ])
                                            ]),
                                        default: _withCtx(()=>[
                                                _createTextVNode(_toDisplayString(_ctx.$t('WEB2_LANDING_ADVENT_TOURNAMENTS_TITLE')) + " ", 1)
                                            ]),
                                        _: 1
                                    }, 8, [
                                        "tag"
                                    ]),
                                    (_openBlock(), _createElementBlock("div", {
                                        key: 0,
                                        class: _normalizeClass(_ctx.$style['advent-calendar-tournaments__swiper-navigation-top'])
                                    }, [
                                        _createVNode(AdventCalendarTournamentsSliderNavigation)
                                    ], 2))
                                ], 2)
                            ]),
                        "pagination-footer": _withCtx(()=>[
                                _createCommentVNode("", true)
                            ]),
                        default: _withCtx(()=>[
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(promotions), (promotion)=>(_openBlock(), _createBlock(VSwiperSlide, {
                                        key: promotion.id,
                                        class: _normalizeClass(_ctx.$style['advent-calendar-tournaments__swiper-slide'])
                                    }, {
                                        default: _withCtx(()=>[
                                                _createVNode(AdventCalendarTournamentItem, {
                                                    promotion: promotion,
                                                    "is-festival-unavailable": _ctx.isFestivalUnavailable,
                                                    onClickOnDetails: ($event)=>clickOnDetails(promotion)
                                                }, null, 8, [
                                                    "promotion",
                                                    "is-festival-unavailable",
                                                    "onClickOnDetails"
                                                ])
                                            ]),
                                        _: 2
                                    }, 1032, [
                                        "class"
                                    ]))), 128))
                            ]),
                        _: 1
                    }, 8, [
                        "use-modern-snap-api",
                        "event-container-class"
                    ])
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'AdventCalendarTournaments'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
