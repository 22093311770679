import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, unref as _unref, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "landing-section-second__list"
};
const _hoisted_2 = [
    "onClick"
];
const _hoisted_3 = {
    class: "landing-section-second__item-col"
};
const _hoisted_4 = {
    class: "landing-section-second__item-image"
};
const _hoisted_5 = {
    class: "landing-section-second__item-col"
};
const _hoisted_6 = {
    class: "landing-section-second__item-paragraph"
};
const _hoisted_7 = {
    class: "landing-section-second__item-title"
};
import VImage from 'web/src/components/Image/VImage/VImage.vue';
import VDynamicContent from 'web/src/components/DynamicContent/VDynamicContent/VDynamicContent.vue';
import VSwiperSlide from 'web/src/components/Swiper/VSwiperSlide/VSwiperSlide.vue';
import VSwiper from 'web/src/components/Swiper/VSwiper/VSwiper';
import VAnimationStarter from 'web/src/components/AnimationStarter/VAnimationStarter/VAnimationStarter.vue';
import { useLandingSectionSecond } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LandingSectionSecond',
    props: {
        title: {},
        label: {},
        list: {},
        isPremium: {
            type: Boolean
        },
        listTabs: {}
    },
    setup (__props) {
        const { setActiveSlide, emitSlideChanged, activeIndex, swiperMain, onTabItemContentClick } = useLandingSectionSecond();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    'landing-section-second': true,
                    'landing-section-second--premium': _ctx.isPremium
                })
            }, [
                _createVNode(VAnimationStarter, {
                    "root-margin": "0px 0px -50px 0px",
                    class: "landing-section-second__header"
                }, {
                    default: _withCtx(()=>[
                            _createVNode(VDynamicContent, {
                                class: "landing-section-second__header-title",
                                content: _ctx.title
                            }, null, 8, [
                                "content"
                            ]),
                            _ctx.label ? (_openBlock(), _createBlock(VDynamicContent, {
                                key: 0,
                                class: "landing-section-second__header-label",
                                content: _ctx.label
                            }, null, 8, [
                                "content"
                            ])) : _createCommentVNode("", true)
                        ]),
                    _: 1
                }),
                _createElementVNode("div", _hoisted_1, [
                    _ctx.listTabs ? (_openBlock(), _createBlock(VAnimationStarter, {
                        key: 0,
                        "root-margin": "0px 0px -50px 0px",
                        class: "landing-section-second__list-header"
                    }, {
                        default: _withCtx(()=>[
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listTabs, (itemTab, index)=>(_openBlock(), _createElementBlock("div", {
                                        key: index,
                                        class: _normalizeClass({
                                            'landing-section-second__list-tab': true,
                                            'landing-section-second__list-tab--active': index === _unref(activeIndex)
                                        }),
                                        onClick: ($event)=>_unref(setActiveSlide)(index, itemTab.label)
                                    }, [
                                        _createElementVNode("div", {
                                            class: "landing-section-second__list-tab-icon",
                                            style: _normalizeStyle({
                                                maskImage: `url(${itemTab.icon})`
                                            })
                                        }, null, 4),
                                        _createElementVNode("span", null, _toDisplayString(itemTab.label), 1)
                                    ], 10, _hoisted_2))), 128))
                            ]),
                        _: 1
                    })) : _createCommentVNode("", true),
                    (_openBlock(), _createBlock(VAnimationStarter, {
                        key: 1,
                        "root-margin": "0px 0px -50px 0px",
                        class: "landing-section-second__list-body"
                    }, {
                        default: _withCtx(()=>[
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (insideList, insideListIndex)=>(_openBlock(), _createElementBlock(_Fragment, null, [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(insideList.list, (item)=>(_openBlock(), _createElementBlock(_Fragment, {
                                                key: item.title
                                            }, [
                                                insideListIndex === _unref(activeIndex) ? (_openBlock(), _createElementBlock("div", {
                                                    key: 0,
                                                    class: "landing-section-second__item",
                                                    onClick: _cache[0] || (_cache[0] = //@ts-ignore
                                                    function() {
                                                        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                                            args[_key] = arguments[_key];
                                                        }
                                                        return _unref(onTabItemContentClick) && _unref(onTabItemContentClick)(...args);
                                                    })
                                                }, [
                                                    _createElementVNode("div", _hoisted_3, [
                                                        _createElementVNode("div", _hoisted_4, [
                                                            item.image ? (_openBlock(), _createBlock(VImage, {
                                                                key: 0,
                                                                src: item.image.value,
                                                                width: "100%"
                                                            }, null, 8, [
                                                                "src"
                                                            ])) : _createCommentVNode("", true)
                                                        ])
                                                    ]),
                                                    _createElementVNode("div", _hoisted_5, [
                                                        _createElementVNode("div", _hoisted_6, [
                                                            _createElementVNode("span", _hoisted_7, _toDisplayString(item.title), 1),
                                                            _createVNode(VDynamicContent, {
                                                                class: "landing-section-second__item-label",
                                                                content: item.label
                                                            }, null, 8, [
                                                                "content"
                                                            ])
                                                        ])
                                                    ])
                                                ])) : _createCommentVNode("", true)
                                            ], 64))), 128))
                                    ], 64))), 256))
                            ]),
                        _: 1
                    }))
                ])
            ], //@ts-ignore
            2)), [
                [
                    _directive_auto_id,
                    'LandingSectionSecond'
                ]
            ]);
        };
    }
});
