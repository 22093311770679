import { defineComponent as _defineComponent } from 'vue';
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import VDynamicContent from 'web/src/components/DynamicContent/VDynamicContent/VDynamicContent.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LandingAndroidChevron',
    props: {
        star: {
            type: Boolean
        },
        label: {
            default: ''
        },
        inverse: {
            type: Boolean
        }
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", null, [
                _createElementVNode("div", {
                    class: _normalizeClass({
                        'landing-android-chevron': true,
                        'landing-android-chevron--inverse': _ctx.inverse
                    })
                }, [
                    _createElementVNode("span", {
                        class: _normalizeClass(_ctx.star ? 'landing-android-chevron--icon' : 'landing-android-chevron--ball')
                    }, null, 2),
                    _createVNode(VDynamicContent, {
                        class: _normalizeClass([
                            "landing-android-chevron__label",
                            {
                                'landing-android-chevron__label': true,
                                'landing-android-chevron__label--inverse': _ctx.inverse
                            }
                        ]),
                        content: _ctx.label
                    }, null, 8, [
                        "class",
                        "content"
                    ])
                ], 2)
            ])), [
                [
                    _directive_auto_id,
                    'LandingAndroidChevron'
                ]
            ]);
        };
    }
});
