import { defineComponent as _defineComponent } from 'vue';
import { openBlock as _openBlock, createBlock as _createBlock } from "vue";
import LandingAndroidFooterLeonru from 'web/src/modules/landings/submodules/android/pages/blocks/LandingAndroidFifth/LandingAndroidFooterLeonru.vue';
import LandingAndroidFooterLeonbets from 'web/src/modules/landings/submodules/android/pages/blocks/LandingAndroidFifth/LandingAndroidFooterLeonbets.vue';
import LandingAndroidFooterSlott from 'web/src/modules/landings/submodules/android/pages/blocks/LandingAndroidFifth/LandingAndroidFooterSlott.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LandingAndroidFifth',
    emits: [
        "scroll-top"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        return (_ctx, _cache)=>(_openBlock(), _createBlock(LandingAndroidFooterLeonbets, {
                key: 2
            }));
    }
});
