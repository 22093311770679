import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "landing-android-first__wrapper"
};
const _hoisted_2 = {
    class: "landing-android-first"
};
import { LandingAndroidFirstBg, LandingAndroidFirstHeader, LandingAndroidFirstContent } from './components';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LandingAndroidFirst',
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                (_openBlock(), _createBlock(_unref(LandingAndroidFirstBg), {
                    key: 0
                })),
                _createElementVNode("div", _hoisted_2, [
                    _createVNode(_unref(LandingAndroidFirstHeader)),
                    _createVNode(_unref(LandingAndroidFirstContent))
                ])
            ])), [
                [
                    _directive_auto_id,
                    'LandingAndroidFirst'
                ]
            ]);
        };
    }
});
