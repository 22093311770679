import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { Timer } from '@leon-hub/utils';
import useCountdown from 'web/src/modules/core/utils/countdown/useCountdown';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LandingEuroCountdown',
    props: {
        timestamp: {}
    },
    setup (__props) {
        const props = __props;
        let intervalId = 0;
        const timeNow = ref(Date.now());
        const targetTime = computed(()=>props.timestamp);
        const { timeLeftValue: timeLeft } = useCountdown(targetTime, timeNow);
        const timersDays = computed(()=>timeLeft.value.days < 10 ? `0${timeLeft.value.days}` : timeLeft.value.days);
        const timerHours = computed(()=>timeLeft.value.hours < 10 ? `0${timeLeft.value.hours}` : timeLeft.value.hours);
        const timerMinutes = computed(()=>timeLeft.value.minutes < 10 ? `0${timeLeft.value.minutes}` : timeLeft.value.minutes);
        const timerSeconds = computed(()=>timeLeft.value.seconds < 10 ? `0${timeLeft.value.seconds}` : timeLeft.value.seconds);
        const timer = computed(()=>{
            if (timeLeft.value.days) return `${timersDays.value}:${timerHours.value}:${timerMinutes.value}:${timerSeconds.value}`;
            return `${timerHours.value}:${timerMinutes.value}:${timerSeconds.value}`;
        });
        const stopTimer = ()=>{
            if (intervalId) {
                clearInterval(intervalId);
                intervalId = 0;
            }
        };
        const startTimer = ()=>{
            timeNow.value = Date.now();
            intervalId = Timer.setInterval(()=>{
                timeNow.value = Date.now();
            }, 1000);
        };
        watch(targetTime, ()=>{
            stopTimer();
            startTimer();
        });
        onMounted(startTimer);
        onBeforeUnmount(stopTimer);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("span", null, [
                _createTextVNode(_toDisplayString(timer.value), 1)
            ])), [
                [
                    _directive_auto_id,
                    'LandingEuroCountdown'
                ]
            ]);
        };
    }
});
