import { defineComponent as _defineComponent } from 'vue';
import { normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import VDynamicContent from 'web/src/components/DynamicContent/VDynamicContent/VDynamicContent.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LandingAndroidTitle',
    props: {
        label: {
            default: ''
        },
        center: {
            type: Boolean
        }
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(VDynamicContent, {
                content: _ctx.label,
                class: _normalizeClass({
                    'landing-android-title': true,
                    'landing-android-title--center': _ctx.center
                })
            }, null, 8, [
                "content",
                "class"
            ])), [
                [
                    _directive_auto_id,
                    'LandingAndroidTitle'
                ]
            ]);
        };
    }
});
