import { onMounted, watch, ref } from 'vue';
import { useWindowResize } from '@leon-hub/browser-composables';
export default function useAdventCalendar(props) {
    const hasStubs = ref(false);
    const stubsItems = ref([]);
    function setStubsVisibility() {
        hasStubs.value = document.documentElement.clientWidth > 1085;
    }
    function updateItems() {
        if (props.items?.length) {
            const startDate = props.items[0].startedAt;
            let startDayOfWeek = new Date(startDate).getDay();
            startDayOfWeek = 0 === startDayOfWeek ? 6 : startDayOfWeek - 1;
            const oneDayMilliseconds = 86400000;
            for(let i = 0; i < startDayOfWeek; i += 1){
                const stubTimestamp = new Date(startDate - i * oneDayMilliseconds).toISOString().split('T')[0];
                stubsItems.value.unshift(stubTimestamp);
            }
            setStubsVisibility();
        }
    }
    useWindowResize(()=>{
        setStubsVisibility();
    });
    onMounted(()=>{
        updateItems();
    });
    watch(()=>props.items?.length, updateItems);
    return {
        hasStubs,
        stubsItems
    };
}
