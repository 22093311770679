import { watch, toRef, ref, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import RouteName from '@leon-hub/routing-config-names';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useAdventStore } from 'web/src/modules/landings/submodules/advent/store';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import DateTime, { TimeFormats } from 'web/src/utils/DateTime';
export default function useAdventRoutePage() {
    const router = useRouter();
    const { isLoggedIn } = useIsLoggedIn();
    const siteConfigStore = useSiteConfigStore();
    const adventStore = useAdventStore();
    const customerDataStore = useCustomerDataStore();
    const analytics = useAnalytics();
    const isPackCalculating = ref(false);
    const imageBackground = toRef(()=>siteConfigStore.landing?.landingAdventBackgroundImage ?? null);
    const allPacks = toRef(()=>adventStore.allPacks);
    const isPacksLoaded = toRef(()=>adventStore.isPacksLoaded);
    const activePack = toRef(()=>adventStore.activePack);
    const festivalDate = toRef(()=>adventStore.festivalDate);
    const isBeforeStart = toRef(()=>adventStore.isBeforeStart);
    const isFestivalUnavailable = toRef(()=>adventStore.isFestivalUnavailable);
    const customerId = toRef(()=>customerDataStore.customerData?.customerLogin);
    const customerCountry = toRef(()=>customerDataStore.customerData?.country);
    const customerIsVip = toRef(()=>customerDataStore.customerData?.isVip);
    const { fetchAllPacks, calculatePack, fetchPack } = adventStore;
    const adventCalendarItems = computed(()=>allPacks.value ?? []);
    const isFestivalEnded = computed(()=>{
        const dateNow = Date.now();
        if (festivalDate.value) return dateNow > festivalDate.value;
        return false;
    });
    const imageDirectory = ref('ru');
    async function onCalendarClick(day) {
        isPackCalculating.value = true;
        analytics.pushGTM(AnalyticsEvent.ADVENT_RECEIVE, {
            event: AnalyticsEvent.ADVENT_RECEIVE,
            customer_id: customerId.value,
            customer_status: 'logged_in',
            vs_lw: customerIsVip.value,
            full_url: window.location.href,
            country: customerCountry.value,
            cell_date: DateTime.formatTimeStamp(Number(new Date(day)), TimeFormats.dayMonthYear, 'en_us')
        });
        if (!activePack.value?.calculated) await calculatePack();
        await fetchPack();
        router.push({
            name: RouteName.BONUS_CALENDAR_PRIZES
        });
        isPackCalculating.value = false;
    }
    async function initialDataRequest() {
        if (isLoggedIn.value) await fetchAllPacks();
    }
    onMounted(()=>{
        initialDataRequest();
        "1";
        imageDirectory.value = 'li';
    });
    watch(isLoggedIn, ()=>{
        initialDataRequest();
    });
    return {
        adventCalendarItems,
        festivalDate,
        imageBackground,
        imageDirectory,
        isBeforeStart,
        isFestivalEnded,
        isFestivalUnavailable,
        isLoggedIn,
        isPackCalculating,
        isPacksLoaded,
        onCalendarClick
    };
}
