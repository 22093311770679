import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { useRouter } from 'vue-router';
import { ref } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { packRewardCollectibleCard } from '@leon-hub/api-sdk';
import { Tag } from '@leon-hub/tags';
import RouteName from '@leon-hub/routing-config-names';
import { VIcon } from '@components/v-icon';
import VImage from 'web/src/components/Image/VImage/VImage.vue';
import VDynamicContent from 'web/src/components/DynamicContent/VDynamicContent/VDynamicContent.vue';
import AdventButton from '../AdventButton/AdventButton.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'AdventCalendarPrizeCard',
    props: {
        image: {},
        title: {},
        description: {},
        isCollected: {
            type: Boolean
        },
        rewardId: {},
        rewardType: {},
        termsKey: {}
    },
    emits: [
        "click",
        "collect"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const router = useRouter();
        const isLoading = ref(false);
        const handleAgreementClick = ()=>{
            router.push({
                name: RouteName.CMS_PROMO_TERMS,
                params: {
                    cmsKey: props.termsKey
                }
            });
        };
        const handleButtonClick = (rewardId)=>{
            if (props.isCollected) {
                emit('click');
                router.push({
                    name: RouteName.BONUSES
                });
            } else if (!isLoading.value) {
                isLoading.value = true;
                emit('collect', rewardId);
            }
        };
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['advent-calendar-prize-card']]: true,
                    [_ctx.$style['advent-calendar-prize-card--is-collectible']]: props.rewardType === _unref(packRewardCollectibleCard)
                })
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['advent-calendar-prize-card__image'])
                }, [
                    _ctx.image ? (_openBlock(), _createBlock(VImage, {
                        key: 0,
                        src: _ctx.image.src,
                        x1: _ctx.image.x1,
                        x2: _ctx.image.x2,
                        x3: _ctx.image.x3,
                        x1webp: _ctx.image.x1webp,
                        x2webp: _ctx.image.x2webp,
                        x3webp: _ctx.image.x3webp,
                        alt: ""
                    }, null, 8, [
                        "src",
                        "x1",
                        "x2",
                        "x3",
                        "x1webp",
                        "x2webp",
                        "x3webp"
                    ])) : _createCommentVNode("", true)
                ], 2),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['advent-calendar-prize-card__content'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['advent-calendar-prize-card__block'])
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['advent-calendar-prize-card__title'])
                        }, [
                            _createVNode(VDynamicContent, {
                                content: _ctx.title
                            }, null, 8, [
                                "content"
                            ])
                        ], 2),
                        (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: _normalizeClass(_ctx.$style['advent-calendar-prize-card__description'])
                        }, [
                            _createVNode(VDynamicContent, {
                                content: _ctx.description
                            }, null, 8, [
                                "content"
                            ])
                        ], 2))
                    ], 2),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['advent-calendar-prize-card__block'])
                    }, [
                        _ctx.isCollected ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: _normalizeClass(_ctx.$style['advent-calendar-prize-card__status'])
                        }, [
                            _createVNode(_unref(VIcon), {
                                name: _unref(IconName).CHECK_ROUNDED,
                                size: _unref(IconSize).SIZE_20
                            }, null, 8, [
                                "name",
                                "size"
                            ]),
                            _createTextVNode(" " + _toDisplayString(_ctx.$t('WEB2_LANDING_ADVENT_PRIZE_CARD_RECEIVED')), 1)
                        ], 2)) : _createCommentVNode("", true),
                        _ctx.rewardType !== _unref(packRewardCollectibleCard) ? (_openBlock(), _createBlock(AdventButton, {
                            key: 1,
                            kind: "primary",
                            size: 'medium',
                            label: _ctx.isCollected ? _ctx.$t('WEB2_LANDING_ADVENT_PRIZE_CARD_BONUSES_BUTTON') : _ctx.$t('WEB2_LANDING_ADVENT_PRIZE_CARD_GET_BUTTON'),
                            class: _normalizeClass(_ctx.$style['advent-calendar-prize-card__button']),
                            "is-loading": !_ctx.isCollected && isLoading.value,
                            onClick: _cache[0] || (_cache[0] = _withModifiers(($event)=>handleButtonClick(_ctx.rewardId), [
                                "stop"
                            ]))
                        }, null, 8, [
                            "size",
                            "label",
                            "class",
                            "is-loading"
                        ])) : _createCommentVNode("", true)
                    ], 2),
                    _ctx.rewardType !== _unref(packRewardCollectibleCard) ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['advent-calendar-prize-card__block'])
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['advent-calendar-prize-card__info'])
                        }, [
                            _createTextVNode(_toDisplayString(_ctx.$t('WEB2_LANDING_ADVENT_PRIZE_CARD_TERMS_PART1')) + " " + _toDisplayString(_ctx.$t('WEB2_LANDING_ADVENT_PRIZE_CARD_TERMS_PART2')) + " ", 1),
                            _withDirectives(_createVNode(VDynamicContent, {
                                tag: _unref(Tag).SPAN,
                                content: _ctx.$t('WEB2_LANDING_ADVENT_PRIZE_CARD_TERMS_PART3'),
                                onClick: handleAgreementClick
                            }, null, 8, [
                                "tag",
                                "content"
                            ]), [
                                [
                                    _directive_data_test,
                                    {
                                        el: 'terms-link'
                                    }
                                ]
                            ])
                        ], 2)
                    ], 2)) : _createCommentVNode("", true)
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'AdventCalendarPrizeCard'
                ]
            ]);
        };
    }
});
