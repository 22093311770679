import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createVNode as _createVNode, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "src"
];
const _hoisted_2 = {
    key: 0
};
import { packListStateActive, packListStateClosed, packListStateUpcoming } from '@leon-hub/api-sdk';
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import { ObjectFitOption } from 'web/src/components/Image/VImage/enums';
import VImage from 'web/src/components/Image/VImage/VImage.vue';
import LandingEuroCountdown from 'web/src/modules/landings/submodules/euro-2024/components/LandingEuroCountdown/LandingEuroCountdown.vue';
import { useAdventCalendarItem } from './composables';
import AdventButton from '../AdventButton/AdventButton.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'AdventCalendarItem',
    props: {
        closedAt: {},
        day: {
            default: ''
        },
        image: {},
        imageDirectory: {},
        isCalculated: {
            type: Boolean
        },
        isCollected: {
            type: Boolean
        },
        isPackCalculating: {
            type: Boolean
        },
        isStub: {
            type: Boolean
        },
        startedAt: {},
        state: {
            default: packListStateUpcoming
        },
        text: {},
        title: {}
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        function onButtonClick() {
            if (!props.isPackCalculating) emit('click', props.day);
        }
        const { itemDay, itemMonth } = useAdventCalendarItem(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['advent-calendar-item']]: true,
                    [_ctx.$style['advent-calendar-item--upcoming']]: _ctx.state === _unref(packListStateUpcoming),
                    [_ctx.$style['advent-calendar-item--active']]: _ctx.state === _unref(packListStateActive) && !_ctx.isCollected,
                    [_ctx.$style['advent-calendar-item--received']]: _ctx.isCollected && (_ctx.state === _unref(packListStateActive) || _ctx.state === _unref(packListStateClosed) && _ctx.isCalculated),
                    [_ctx.$style['advent-calendar-item--closed']]: _ctx.state === _unref(packListStateClosed) && (!_ctx.isCalculated || !_ctx.isCollected),
                    [_ctx.$style['advent-calendar-item--stub']]: _ctx.isStub
                })
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['advent-calendar-item__date'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['advent-calendar-item__date-day'])
                    }, _toDisplayString(_unref(itemDay)), 3),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['advent-calendar-item__date-month'])
                    }, _toDisplayString(_unref(itemMonth)), 3)
                ], 2),
                _ctx.isStub ? _createCommentVNode("", true) : (_openBlock(), _createElementBlock(_Fragment, {
                    key: 0
                }, [
                    _ctx.state !== _unref(packListStateActive) || _ctx.isCollected ? _createCommentVNode("", true) : (_openBlock(), _createBlock(LandingEuroCountdown, {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['advent-calendar-item__countdown']),
                        timestamp: _ctx.closedAt
                    }, null, 8, [
                        "class",
                        "timestamp"
                    ])),
                    _createElementVNode("div", {
                        class: _normalizeClass({
                            [_ctx.$style['advent-calendar-item__image']]: true,
                            [_ctx.$style['advent-calendar-item__image--stub']]: _ctx.image && _ctx.state === _unref(packListStateUpcoming)
                        })
                    }, [
                        _ctx.state === _unref(packListStateClosed) || _ctx.state === _unref(packListStateActive) ? (_openBlock(), _createElementBlock("img", {
                            key: 0,
                            src: require(`web/src/modules/landings/submodules/advent/images/${_ctx.imageDirectory}/${_ctx.isCollected ? 'gift-opened' : 'gift-closed'}.png`),
                            alt: ""
                        }, null, 8, _hoisted_1)) : _createCommentVNode("", true),
                        _ctx.image && _ctx.state === _unref(packListStateUpcoming) ? (_openBlock(), _createBlock(VImage, {
                            key: 1,
                            src: _ctx.image.src,
                            x1: _ctx.image.x1,
                            x2: _ctx.image.x2,
                            x3: _ctx.image.x3,
                            x1webp: _ctx.image.x1webp,
                            x2webp: _ctx.image.x2webp,
                            x3webp: _ctx.image.x3webp,
                            alt: "",
                            "object-fit": _unref(ObjectFitOption).CONTAIN
                        }, null, 8, [
                            "src",
                            "x1",
                            "x2",
                            "x3",
                            "x1webp",
                            "x2webp",
                            "x3webp",
                            "object-fit"
                        ])) : _createCommentVNode("", true)
                    ], 2),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['advent-calendar-item__info'])
                    }, [
                        _ctx.state === _unref(packListStateUpcoming) ? (_openBlock(), _createElementBlock("div", _hoisted_2)) : _createCommentVNode("", true),
                        _ctx.state === _unref(packListStateActive) ? (_openBlock(), _createElementBlock(_Fragment, {
                            key: 1
                        }, [
                            _ctx.isCollected ? (_openBlock(), _createElementBlock(_Fragment, {
                                key: 1
                            }, [
                                _createElementVNode("div", {
                                    class: _normalizeClass(_ctx.$style['advent-calendar-item__icon'])
                                }, [
                                    _createVNode(_unref(VIcon), {
                                        name: _unref(IconName).CHECK_ROUNDED,
                                        size: _unref(IconSize).SIZE_14
                                    }, null, 8, [
                                        "name",
                                        "size"
                                    ])
                                ], 2),
                                _createElementVNode("div", {
                                    class: _normalizeClass(_ctx.$style['advent-calendar-item__subtitle'])
                                }, _toDisplayString(_ctx.$t('WEB2_LANDING_ADVENT_CALENDAR_ITEM_RECEIVED')), 3)
                            ], 64)) : (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: _normalizeClass(_ctx.$style['advent-calendar-item__subtitle'])
                            }, [
                                _createVNode(AdventButton, {
                                    kind: "tertiary",
                                    size: "small",
                                    "full-width": "",
                                    label: _ctx.$t('WEB2_LANDING_ADVENT_CALENDAR_ITEM_BUTTON_CLAIM'),
                                    class: _normalizeClass(_ctx.$style['advent-calendar-item__button']),
                                    "is-loading": _ctx.isPackCalculating,
                                    onClick: _withModifiers(onButtonClick, [
                                        "stop"
                                    ])
                                }, null, 8, [
                                    "label",
                                    "class",
                                    "is-loading"
                                ])
                            ], 2))
                        ], 64)) : _createCommentVNode("", true),
                        _ctx.state === _unref(packListStateClosed) ? (_openBlock(), _createElementBlock(_Fragment, {
                            key: 2
                        }, [
                            _ctx.isCollected ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: _normalizeClass(_ctx.$style['advent-calendar-item__icon'])
                            }, [
                                _createVNode(_unref(VIcon), {
                                    name: _unref(IconName).CHECK_ROUNDED,
                                    size: _unref(IconSize).SIZE_14
                                }, null, 8, [
                                    "name",
                                    "size"
                                ])
                            ], 2)) : _createCommentVNode("", true),
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['advent-calendar-item__subtitle'])
                            }, _toDisplayString(_ctx.isCalculated && _ctx.isCollected ? _ctx.$t('WEB2_LANDING_ADVENT_CALENDAR_ITEM_RECEIVED') : _ctx.$t('WEB2_LANDING_ADVENT_CALENDAR_ITEM_TIME_IS_UP')), 3)
                        ], 64)) : _createCommentVNode("", true)
                    ], 2)
                ], 64))
            ], 2)), [
                [
                    _directive_auto_id,
                    'AdventCalendarItem'
                ]
            ]);
        };
    }
});
