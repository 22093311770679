import { ref } from 'vue';
export function useNativeAppInterface() {
    return {
        isWebView: ref(false),
        openPromotion () {},
        openBonuses () {},
        openLogin () {},
        openAllPromotions () {},
        openTop () {},
        openLive () {}
    };
}
