import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
import { Locale } from '@leon-hub/locale';
import VImage from 'web/src/components/Image/VImage/VImage.vue';
import { useLandingImages } from 'web/src/modules/landings/submodules/android/pages/useLandingImages';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LandingAndroidFirstImage',
    setup (__props) {
        const { srcFirstBgImage, firstImage } = useLandingImages();
        return (_ctx, _cache)=>(_openBlock(), _createElementBlock(_Fragment, null, [
                _createElementVNode("div", {
                    class: _normalizeClass({
                        'landing-android-first-image__container': true,
                        'landing-android-first-image__container--in': _ctx.$locale === _unref(Locale).EN_IN
                    })
                }, [
                    _createVNode(VImage, {
                        class: "landing-android-first-image animate-hero animate-hero--desktop",
                        src: _unref(firstImage)
                    }, null, 8, [
                        "src"
                    ])
                ], 2),
                _createElementVNode("div", null, [
                    _createCommentVNode("", true)
                ])
            ], 64));
    }
});
