import { defineComponent as _defineComponent } from 'vue';
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "landing-android-second"
};
import { toRef } from 'vue';
import VDynamicContent from 'web/src/components/DynamicContent/VDynamicContent/VDynamicContent.vue';
import VImage from 'web/src/components/Image/VImage/VImage.vue';
import VAnimationStarter from 'web/src/components/AnimationStarter/VAnimationStarter/VAnimationStarter.vue';
import LandingAndroidTitle from 'web/src/modules/landings/submodules/android/pages/components/LandingAndroidTitle/LandingAndroidTitle.vue';
import { useLandingImages } from 'web/src/modules/landings/submodules/android/pages/useLandingImages';
import LandingAndroidChevron from 'web/src/modules/landings/submodules/android/pages/components/LandingAndroidChevron/LandingAndroidChevron.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LandingAndroidSecond',
    setup (__props) {
        const secondListImages = toRef(useLandingImages(), 'secondListImages');
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(VAnimationStarter, {
                    "root-margin": "0px 0px -50px 0px",
                    class: "landing-android-second__chevron"
                }, {
                    default: _withCtx(()=>[
                            _createCommentVNode("", true)
                        ]),
                    _: 1
                }),
                _createVNode(VAnimationStarter, {
                    "root-margin": "0px 0px -50px 0px",
                    class: "landing-android-second__title"
                }, {
                    default: _withCtx(()=>[
                            _createVNode(LandingAndroidTitle, {
                                center: "",
                                label: _ctx.$t('WEB2_ANDROID_LANDING_TITLE_2')
                            }, null, 8, [
                                "label"
                            ])
                        ]),
                    _: 1
                }),
                _createVNode(VAnimationStarter, {
                    class: "landing-android-second__list",
                    "root-margin": "0px 0px -50px 0px"
                }, {
                    default: _withCtx(()=>[
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(secondListImages.value, (image, index)=>(_openBlock(), _createElementBlock("div", {
                                    key: index,
                                    class: "landing-android-second__item"
                                }, [
                                    _createElementVNode("div", null, [
                                        _createVNode(VImage, {
                                            class: "landing-android-second__item-image",
                                            src: image
                                        }, null, 8, [
                                            "src"
                                        ])
                                    ]),
                                    _createElementVNode("div", null, [
                                        0 === index ? (_openBlock(), _createBlock(VDynamicContent, {
                                            key: 0,
                                            class: "landing-android-second__item-title",
                                            content: _ctx.$t('WEB2_ANDROID_LANDING_ITEM_TITLE_2_1')
                                        }, null, 8, [
                                            "content"
                                        ])) : _createCommentVNode("", true),
                                        1 === index ? (_openBlock(), _createBlock(VDynamicContent, {
                                            key: 1,
                                            class: "landing-android-second__item-title",
                                            content: _ctx.$t('WEB2_ANDROID_LANDING_ITEM_TITLE_2_2')
                                        }, null, 8, [
                                            "content"
                                        ])) : _createCommentVNode("", true),
                                        2 === index ? (_openBlock(), _createBlock(VDynamicContent, {
                                            key: 2,
                                            class: "landing-android-second__item-title",
                                            content: _ctx.$t('WEB2_ANDROID_LANDING_ITEM_TITLE_2_3')
                                        }, null, 8, [
                                            "content"
                                        ])) : _createCommentVNode("", true),
                                        0 === index ? (_openBlock(), _createBlock(VDynamicContent, {
                                            key: 3,
                                            class: "landing-android-second__item-text",
                                            content: _ctx.$t('WEB2_ANDROID_LANDING_ITEM_TEXT_2_1')
                                        }, null, 8, [
                                            "content"
                                        ])) : _createCommentVNode("", true),
                                        1 === index ? (_openBlock(), _createBlock(VDynamicContent, {
                                            key: 4,
                                            class: "landing-android-second__item-text",
                                            content: _ctx.$t('WEB2_ANDROID_LANDING_ITEM_TEXT_2_2')
                                        }, null, 8, [
                                            "content"
                                        ])) : _createCommentVNode("", true),
                                        2 === index ? (_openBlock(), _createBlock(VDynamicContent, {
                                            key: 5,
                                            class: "landing-android-second__item-text",
                                            content: _ctx.$t('WEB2_ANDROID_LANDING_ITEM_TEXT_2_3')
                                        }, null, 8, [
                                            "content"
                                        ])) : _createCommentVNode("", true)
                                    ])
                                ]))), 128))
                        ]),
                    _: 1
                })
            ])), [
                [
                    _directive_auto_id,
                    'LandingAndroidSecond'
                ]
            ]);
        };
    }
});
