import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "advent-section-header__subtitle"
};
import VAnimationStarter from 'web/src/components/AnimationStarter/VAnimationStarter/VAnimationStarter.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'AdventSectionTitle',
    props: {
        tag: {}
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(VAnimationStarter, {
                "root-margin": "0px 0px -50px 0px",
                class: "advent-section-header"
            }, {
                default: _withCtx(()=>[
                        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tag), {
                            class: "advent-section-header__title"
                        }, {
                            default: _withCtx(()=>[
                                    _renderSlot(_ctx.$slots, "default")
                                ]),
                            _: 3
                        })),
                        _ctx.$slots.text ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                            _renderSlot(_ctx.$slots, "text")
                        ])) : _createCommentVNode("", true)
                    ]),
                _: 3
            })), [
                [
                    _directive_auto_id,
                    'AdventSectionTitle'
                ]
            ]);
        };
    }
});
