import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { useAdventPrizesModalRoutePage } from './composables';
import AdventCalendarPrizeCard from '../../components/AdventCalendarPrizeCard/AdventCalendarPrizeCard.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'AdventPrizesModalRoutePage',
    setup (__props) {
        const { packRewards, onRewardButtonClick } = useAdventPrizesModalRoutePage();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['advent-prizes-modal'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['advent-prizes-modal__content'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['advent-prizes-modal__list'])
                    }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(packRewards), (reward, index)=>(_openBlock(), _createBlock(AdventCalendarPrizeCard, {
                                key: index,
                                image: reward.visualDetails.image || void 0,
                                title: reward.visualDetails.title || '',
                                description: reward.visualDetails.text || '',
                                "is-collected": reward.collected,
                                "reward-id": reward.rewardId,
                                "reward-type": reward.type,
                                "terms-key": reward.bonusDetails?.termsOfActions || '',
                                onCollect: ($event)=>_unref(onRewardButtonClick)(reward)
                            }, null, 8, [
                                "image",
                                "title",
                                "description",
                                "is-collected",
                                "reward-id",
                                "reward-type",
                                "terms-key",
                                "onCollect"
                            ]))), 128))
                    ], 2)
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'AdventPrizesModalRoutePage'
                ]
            ]);
        };
    }
});
