import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "src"
];
const _hoisted_2 = [
    "src"
];
import { Tag } from '@leon-hub/tags';
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import { ObjectFitOption } from 'web/src/components/Image/VImage/enums';
import { VLoaderDelayed } from 'web/src/components/Loader';
import LandingEuroSectionTitle from 'web/src/modules/landings/submodules/euro-2024/components/LandingEuroSectionTitle/LandingEuroSectionTitle.vue';
import VImage from 'web/src/components/Image/VImage/VImage.vue';
import { useAdventCalendarTournamentItem } from './composables';
import AdventButton from '../AdventButton/AdventButton.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'AdventCalendarTournamentItem',
    props: {
        promotion: {},
        isFestivalUnavailable: {
            type: Boolean
        }
    },
    emits: [
        "click-on-details"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { imageSuffix, isDataLoaded, isLoadingButton, isLoggedIn, leaderBoardData, onClickPromoJoin } = useAdventCalendarTournamentItem(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _ctx.promotion ? _withDirectives((_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(_ctx.$style['advent-calendar-tournament-item'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['advent-calendar-tournament-item__top'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['advent-calendar-tournament-item__image'])
                    }, [
                        _ctx.promotion?.landingPageImage ? (_openBlock(), _createBlock(VImage, _mergeProps({
                            key: 0
                        }, _ctx.promotion.landingPageImage, {
                            "object-fit": _unref(ObjectFitOption).COVER,
                            class: _ctx.$style['advent-calendar-tournament-item__image-img'],
                            "is-lazy": "",
                            "show-loader": ""
                        }), null, 16, [
                            "object-fit",
                            "class"
                        ])) : (_openBlock(), _createElementBlock("img", {
                            key: 1,
                            src: require('web/src/assets/images/landing/euro/tournaments/image.svg'),
                            alt: ""
                        }, null, 8, _hoisted_1))
                    ], 2),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['advent-calendar-tournament-item__content'])
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['advent-calendar-tournament-item__info'])
                        }, [
                            _createVNode(LandingEuroSectionTitle, {
                                tag: _unref(Tag).H4,
                                class: _normalizeClass(_ctx.$style['advent-calendar-tournament-item__name'])
                            }, {
                                default: _withCtx(()=>[
                                        _createTextVNode(_toDisplayString(_ctx.promotion?.name), 1)
                                    ]),
                                _: 1
                            }, 8, [
                                "tag",
                                "class"
                            ]),
                            _createElementVNode("div", {
                                class: _normalizeClass({
                                    [_ctx.$style['advent-calendar-tournament-item__text']]: true,
                                    [_ctx.$style['advent-calendar-tournament-item__text--special']]: void 0
                                })
                            }, _toDisplayString(_ctx.promotion?.shortDescription), 3)
                        ], 2)
                    ], 2)
                ], 2),
                _unref(isDataLoaded) ? (_openBlock(), _createElementBlock(_Fragment, {
                    key: 1
                }, [
                    _unref(leaderBoardData) && !!_unref(leaderBoardData).winners.length ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['advent-calendar-tournament-item__leaderboard'])
                    }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(leaderBoardData).winners, (winner, index)=>(_openBlock(), _createElementBlock("div", {
                                key: index,
                                class: _normalizeClass({
                                    [_ctx.$style['advent-calendar-tournament-item__leaderboard-item']]: true,
                                    [_ctx.$style['advent-calendar-tournament-item__leaderboard-item-my']]: _unref(leaderBoardData).customerPosition?.place && _unref(leaderBoardData).customerPosition.place === winner.place
                                })
                            }, [
                                _createElementVNode("div", {
                                    class: _normalizeClass(_ctx.$style['advent-calendar-tournament-item__leaderboard-item-place'])
                                }, [
                                    _createElementVNode("span", {
                                        class: _normalizeClass(_ctx.$style['leaderboard-item-label'])
                                    }, _toDisplayString(winner.place), 3),
                                    (_openBlock(), _createElementBlock("img", {
                                        key: 0,
                                        src: require(`web/src/assets/images/landing/euro/tournaments/${index + 1}_place_${_unref(imageSuffix)}.svg`),
                                        alt: "tournament place"
                                    }, null, 8, _hoisted_2))
                                ], 2),
                                _createElementVNode("div", {
                                    class: _normalizeClass(_ctx.$style['advent-calendar-tournament-item__leaderboard-item-name'])
                                }, _toDisplayString(_unref(leaderBoardData)?.customerPosition?.place === winner.place ? _ctx.$t('WEB2_LANDING_ADVENT_TOURNAMENT_YOUR_POSITION') : '-' === winner.name ? _ctx.$t('WEB2_LANDING_ADVENT_TOURNAMENT_HIDDEN_USER') : winner.name), 3),
                                _createElementVNode("span", {
                                    class: _normalizeClass(_ctx.$style['advent-calendar-tournament-item__leaderboard-item-prize'])
                                }, _toDisplayString(winner.prize), 3)
                            ], 2))), 128)),
                        _unref(leaderBoardData)?.customerPosition?.place && _unref(leaderBoardData).customerPosition.place > 3 ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: _normalizeClass({
                                [_ctx.$style['advent-calendar-tournament-item__leaderboard-item']]: true,
                                [_ctx.$style['advent-calendar-tournament-item__leaderboard-item-my']]: true
                            })
                        }, [
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['advent-calendar-tournament-item__leaderboard-item-label'])
                            }, [
                                _createElementVNode("span", {
                                    class: _normalizeClass(_ctx.$style['leaderboard-item-label'])
                                }, _toDisplayString(_unref(leaderBoardData)?.customerPosition?.place || '-'), 3)
                            ], 2),
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['advent-calendar-tournament-item__leaderboard-item-name'])
                            }, _toDisplayString(_ctx.$t('WEB2_LANDING_ADVENT_TOURNAMENT_YOUR_POSITION')), 3),
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['advent-calendar-tournament-item__leaderboard-item-prize'])
                            }, _toDisplayString(_unref(leaderBoardData)?.customerPosition?.prize || ''), 3)
                        ], 2)) : _createCommentVNode("", true)
                    ], 2)) : _createCommentVNode("", true),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['advent-calendar-tournament-item__buttons'])
                    }, [
                        _ctx.isFestivalUnavailable ? (_openBlock(), _createBlock(AdventButton, {
                            key: 1,
                            label: _ctx.$t('WEB2_LANDING_ADVENT_UNAVAILABLE'),
                            kind: "primary",
                            disabled: "",
                            size: 'medium'
                        }, null, 8, [
                            "label",
                            "size"
                        ])) : (_openBlock(), _createElementBlock(_Fragment, {
                            key: 0
                        }, [
                            _unref(leaderBoardData)?.customerPosition ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: _normalizeClass(_ctx.$style['advent-calendar-tournament-item__status-check'])
                            }, [
                                _createVNode(_unref(VIcon), {
                                    name: _unref(IconName).CHECK_ROUNDED,
                                    size: _unref(IconSize).SIZE_18
                                }, null, 8, [
                                    "name",
                                    "size"
                                ]),
                                _createElementVNode("span", null, _toDisplayString(_ctx.$t('WEB2_LANDING_ADVENT_TOURNAMENT_YOU_JOINED')), 1)
                            ], 2)) : (_openBlock(), _createBlock(AdventButton, {
                                key: 1,
                                label: _unref(isLoggedIn) ? _ctx.$t('WEB2_LANDING_ADVENT_TOURNAMENT_BUTTON_JOIN') : _ctx.$t('WEB2_DO_LOGIN'),
                                size: 'medium',
                                kind: "primary",
                                "is-loading": _unref(isLoadingButton),
                                onClick: _unref(onClickPromoJoin)
                            }, null, 8, [
                                "label",
                                "size",
                                "is-loading",
                                "onClick"
                            ])),
                            _createVNode(AdventButton, {
                                label: _ctx.$t('WEB2_LANDING_ADVENT_TOURNAMENT_BUTTON_INFO'),
                                kind: "secondary",
                                size: 'medium',
                                onClick: _cache[0] || (_cache[0] = ($event)=>emit('click-on-details'))
                            }, null, 8, [
                                "label",
                                "size"
                            ])
                        ], 64))
                    ], 2)
                ], 64)) : (_openBlock(), _createBlock(_unref(VLoaderDelayed), {
                    key: 0
                }))
            ], 2)), [
                [
                    _directive_auto_id,
                    'AdventCalendarTournamentItem'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
