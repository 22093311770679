import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
export default /*@__PURE__*/ _defineComponent({
    __name: 'LandingEuroSectionTitle',
    props: {
        tag: {}
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tag), {
                class: _normalizeClass(_ctx.$style['landing-euro-section-title'])
            }, {
                default: _withCtx(()=>[
                        _renderSlot(_ctx.$slots, "default")
                    ]),
                _: 3
            }, 8, [
                "class"
            ])), [
                [
                    _directive_auto_id,
                    'LandingEuroSectionTitle'
                ]
            ]);
        };
    }
});
