import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "android-landing__wrapper"
};
import { useRouter } from 'vue-router';
import { computed, onMounted, ref, toRef } from 'vue';
import RouteName from '@leon-hub/routing-config-names';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import LandingAndroidFirstSlott from 'web/src/modules/landings/submodules/android/pages/blocks/LandingAndroidFirst/LandingAndroidFirstSlott.vue';
import { useI18nLocale } from 'web/src/modules/i18n/composables';
import { LandingAndroidFifth, LandingAndroidFirst, LandingAndroidFourth, LandingAndroidSecond, LandingAndroidThird } from './blocks';
export default /*@__PURE__*/ _defineComponent({
    __name: 'AndroidRoutePage',
    setup (__props) {
        const siteConfigStore = useSiteConfigStore();
        const isLandingAndroidEnabled = toRef(siteConfigStore, 'isLandingAndroidEnabled');
        const mobileAppCordovaBlock = toRef(siteConfigStore, 'mobileAppCordovaBlock');
        const mobileAppDownloadEnabled = computed(()=>!!mobileAppCordovaBlock.value?.mobileAppDownloadEnabled);
        const isAndroidAppEnabled = computed(()=>mobileAppDownloadEnabled.value && !!mobileAppCordovaBlock.value?.mobileAppAndroidDownloadEnabled);
        const router = useRouter();
        const { locale } = useI18nLocale();
        const redirectCheckCompleted = ref(false);
        const androidLanding = ref();
        onMounted(async ()=>{
            if (isLandingAndroidEnabled.value) redirectCheckCompleted.value = true;
            else if (mobileAppDownloadEnabled.value && isAndroidAppEnabled.value) {
                await router.push({
                    name: RouteName.HOME
                });
                window.location.href = `${mobileAppCordovaBlock.value?.mobileAppAndroidDownloadUrl ?? '/app'}?l=${locale.value}`;
            } else router.push({
                name: RouteName.ERROR_NOT_FOUND_404
            });
        });
        function scrollToTop() {
            if (androidLanding.value) androidLanding.value?.scrollIntoView({
                behavior: 'smooth'
            });
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return redirectCheckCompleted.value ? _withDirectives((_openBlock(), _createElementBlock("div", {
                key: 0,
                ref_key: "androidLanding",
                ref: androidLanding,
                class: "android-landing"
            }, [
                _createElementVNode("div", _hoisted_1, [
                    (_openBlock(), _createBlock(_unref(LandingAndroidFirst), {
                        key: 0
                    })),
                    _createCommentVNode("", true),
                    _createVNode(_unref(LandingAndroidSecond)),
                    _createVNode(_unref(LandingAndroidThird)),
                    _createVNode(_unref(LandingAndroidFourth)),
                    _createVNode(_unref(LandingAndroidFifth), {
                        onScrollTop: scrollToTop
                    })
                ])
            ])), [
                [
                    _directive_auto_id,
                    'AndroidRoutePage'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
