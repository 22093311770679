import { onBeforeMount, toRef } from 'vue';
import { useAdventStore } from 'web/src/modules/landings/submodules/advent/store';
export default function useAdventCalendarTournaments() {
    const adventStore = useAdventStore();
    const promotions = toRef(adventStore, 'promotions');
    onBeforeMount(async ()=>{
        await adventStore.loadPromotions();
    });
    return {
        promotions
    };
}
