import { ref } from 'vue';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useAnalytics } from 'web/src/modules/analytics/composables';
export default function useLandingSectionSecond() {
    const { isLoggedIn } = useIsLoggedIn();
    const analytics = useAnalytics();
    const activeIndex = ref(0);
    const swiperMain = ref(null);
    const analyticsKey = isLoggedIn.value ? 'auth' : 'non_auth';
    function setActiveSlide(index, label) {
        activeIndex.value = index;
        analytics.push(AnalyticsEvent.Z_ADVANTAGES_TAB_VIP, {
            vip_program: {
                click_on_tab: analyticsKey,
                title_tab: label
            }
        });
    }
    function emitSlideChanged(event) {
        activeIndex.value = event.activeSlide;
    }
    function onTabItemContentClick() {
        analytics.push(AnalyticsEvent.Z_MISCLICK_ADVANTAGES, {
            vip_program: {
                advantages_misclick: 'true'
            }
        });
    }
    return {
        swiperMain,
        activeIndex,
        setActiveSlide,
        emitSlideChanged,
        onTabItemContentClick
    };
}
