import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "landing-android-first-header"
};
import VBrand from 'web/src/components/Brand/VBrand/VBrand.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LandingAndroidFirstHeader',
    setup (__props) {
        return (_ctx, _cache)=>{
            const _component_router_link = _resolveComponent("router-link");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_router_link, {
                    class: "landing-android-first-header__link",
                    to: _ctx.$to(_ctx.$routeName.HOME)
                }, {
                    default: _withCtx(()=>[
                            _createVNode(VBrand, {
                                "is-dark": true,
                                "is-landing": "",
                                class: "landing-android-first-header__logo"
                            }, null, 8, [
                                "is-dark"
                            ])
                        ]),
                    _: 1
                }, 8, [
                    "to"
                ])
            ])), [
                [
                    _directive_auto_id,
                    'LandingAndroidFirstHeader'
                ]
            ]);
        };
    }
});
