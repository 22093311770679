import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue";
import { useLandingImages } from 'web/src/modules/landings/submodules/android/pages/useLandingImages';
import LandingAndroidFirstImage from 'web/src/modules/landings/submodules/android/pages/blocks/LandingAndroidFirst/components/LandingAndroidFirstImage.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LandingAndroidFirstBg',
    setup (__props) {
        const { srcFirstBgImage } = useLandingImages();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "landing-android-first-pattern",
                style: _normalizeStyle({
                    backgroundImage: `url(${_unref(srcFirstBgImage)})`
                })
            }, [
                _createVNode(LandingAndroidFirstImage)
            ], 4)), [
                [
                    _directive_auto_id,
                    'LandingAndroidFirstBg'
                ]
            ]);
        };
    }
});
