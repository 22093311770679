import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "landing-android-footer"
};
const _hoisted_2 = {
    class: "landing-android-footer__button-row"
};
const _hoisted_3 = {
    class: "landing-android-footer__brand"
};
import LandingAndroidTitle from 'web/src/modules/landings/submodules/android/pages/components/LandingAndroidTitle/LandingAndroidTitle.vue';
import LandingAndroidButton from 'web/src/modules/landings/submodules/android/pages/components/LandingAndroidButton/LandingAndroidButton.vue';
import VBrand from 'web/src/components/Brand/VBrand/VBrand.vue';
import FooterCopyright from 'web/src/modules/core/components/FooterCopyright/FooterCopyright.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LandingAndroidFooterLeonbets',
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(LandingAndroidTitle, {
                    class: "landing-android-footer__title",
                    label: _ctx.$t('WEB2_ANDROID_LANDING_TITLE_5'),
                    center: ""
                }, null, 8, [
                    "label"
                ]),
                _createElementVNode("div", _hoisted_2, [
                    _createVNode(LandingAndroidButton)
                ]),
                _createElementVNode("div", _hoisted_3, [
                    _createVNode(VBrand, {
                        "is-dark": "",
                        "is-landing": ""
                    })
                ]),
                _createVNode(FooterCopyright, {
                    class: "landing-android-footer__copyright",
                    "is-landing": ""
                })
            ])), [
                [
                    _directive_auto_id,
                    'LandingAndroidFooterLeonbets'
                ]
            ]);
        };
    }
});
