import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "landing-android-fourth"
};
const _hoisted_3 = {
    class: "landing-android-fourth__item-header"
};
const _hoisted_4 = {
    key: 0,
    class: "landing-android-fourth__item-number"
};
const _hoisted_5 = {
    class: "landing-android-fourth__item-body"
};
import { toRef } from 'vue';
import VImage from 'web/src/components/Image/VImage/VImage.vue';
import VDynamicContent from 'web/src/components/DynamicContent/VDynamicContent/VDynamicContent.vue';
import VAnimationStarter from 'web/src/components/AnimationStarter/VAnimationStarter/VAnimationStarter.vue';
import LandingAndroidTitle from 'web/src/modules/landings/submodules/android/pages/components/LandingAndroidTitle/LandingAndroidTitle.vue';
import { useLandingImages } from 'web/src/modules/landings/submodules/android/pages/useLandingImages';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LandingAndroidFourth',
    setup (__props) {
        const fourthListImages = toRef(useLandingImages(), 'fourthListImages');
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(LandingAndroidTitle, {
                    class: "landing-android-fourth__title",
                    label: _ctx.$t('WEB2_ANDROID_LANDING_TITLE_4')
                }, null, 8, [
                    "label"
                ]),
                _createVNode(VAnimationStarter, {
                    class: "landing-android-fourth__list",
                    "root-margin": "0px 0px -50px 0px"
                }, {
                    default: _withCtx(()=>[
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(fourthListImages.value, (image, index)=>(_openBlock(), _createElementBlock("div", {
                                    key: index,
                                    class: "landing-android-fourth__item"
                                }, [
                                    _createCommentVNode("", true),
                                    _createElementVNode("div", _hoisted_3, [
                                        _createVNode(VImage, {
                                            class: "landing-android-fourth__item-image",
                                            src: image
                                        }, null, 8, [
                                            "src"
                                        ]),
                                        (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                            _createTextVNode(_toDisplayString(`0${index + 1}`), 1),
                                            _cache[0] || (_cache[0] = _createElementVNode("span", null, ".", -1))
                                        ]))
                                    ]),
                                    _createElementVNode("div", _hoisted_5, [
                                        0 === index ? (_openBlock(), _createBlock(VDynamicContent, {
                                            key: 0,
                                            class: "landing-android-fourth__item-title",
                                            content: _ctx.$t('WEB2_ANDROID_LANDING_ITEM_TITLE_4_1')
                                        }, null, 8, [
                                            "content"
                                        ])) : _createCommentVNode("", true),
                                        1 === index ? (_openBlock(), _createBlock(VDynamicContent, {
                                            key: 1,
                                            class: "landing-android-fourth__item-title",
                                            content: _ctx.$t('WEB2_ANDROID_LANDING_ITEM_TITLE_4_2')
                                        }, null, 8, [
                                            "content"
                                        ])) : _createCommentVNode("", true),
                                        2 === index ? (_openBlock(), _createBlock(VDynamicContent, {
                                            key: 2,
                                            class: "landing-android-fourth__item-title",
                                            content: _ctx.$t('WEB2_ANDROID_LANDING_ITEM_TITLE_4_3')
                                        }, null, 8, [
                                            "content"
                                        ])) : _createCommentVNode("", true),
                                        0 === index ? (_openBlock(), _createBlock(VDynamicContent, {
                                            key: 3,
                                            class: "landing-android-fourth__item-label",
                                            content: _ctx.$t('WEB2_ANDROID_LANDING_ITEM_TEXT_4_1')
                                        }, null, 8, [
                                            "content"
                                        ])) : _createCommentVNode("", true),
                                        1 === index ? (_openBlock(), _createBlock(VDynamicContent, {
                                            key: 4,
                                            class: "landing-android-fourth__item-label",
                                            content: _ctx.$t('WEB2_ANDROID_LANDING_ITEM_TEXT_4_2')
                                        }, null, 8, [
                                            "content"
                                        ])) : _createCommentVNode("", true),
                                        2 === index ? (_openBlock(), _createBlock(VDynamicContent, {
                                            key: 5,
                                            class: "landing-android-fourth__item-label",
                                            content: _ctx.$t('WEB2_ANDROID_LANDING_ITEM_TEXT_4_3')
                                        }, null, 8, [
                                            "content"
                                        ])) : _createCommentVNode("", true)
                                    ])
                                ]))), 128))
                        ]),
                    _: 1
                })
            ])), [
                [
                    _directive_auto_id,
                    'LandingAndroidFourth'
                ]
            ]);
        };
    }
});
