import { ref, computed } from 'vue';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
export default function useLandingSectionFaq(props) {
    const analytics = useAnalytics();
    const { isLoggedIn } = useIsLoggedIn();
    const openedItemId = ref(0);
    const mid = Math.ceil(props.faqList.length / 2);
    const faqListFirst = computed(()=>props.faqList.slice(0, mid));
    const faqListSecond = computed(()=>props.faqList.slice(mid));
    function toggleItem(id, title) {
        const analyticsKey = isLoggedIn.value ? 'auth' : 'non_auth';
        if (openedItemId.value !== id) {
            analytics.push(AnalyticsEvent.Z_FAQ_ARTICLE_VIP, {
                vip_program: {
                    click_on_faq_item: analyticsKey,
                    title_faq_item: title
                }
            });
            openedItemId.value = id;
        } else openedItemId.value = 0;
    }
    return {
        faqListFirst,
        faqListSecond,
        openedItemId,
        toggleItem
    };
}
