import { computed, toRef } from 'vue';
import { useI18nStore } from 'web/src/modules/i18n/store';
import DateTime, { TimeFormats } from 'web/src/utils/DateTime';
export default function useAdventCalendarItem(props) {
    const i18nStore = useI18nStore();
    const locale = toRef(i18nStore, 'locale');
    const itemDay = computed(()=>DateTime.formatTimeStamp(Number(new Date(props.day)), TimeFormats.day));
    const itemMonth = computed(()=>DateTime.formatTimeStamp(Number(new Date(props.day)), TimeFormats.monthShortLocal, locale.value));
    return {
        itemDay,
        itemMonth
    };
}
